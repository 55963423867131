import { FaEdit } from "react-icons/fa"; 
import {  AppBar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography } from '@mui/material'
  import React, { useEffect, useRef, useState } from 'react'

import { FaSortAlphaDown, FaSortAlphaUp, FaRegEnvelope } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { valuationFilterInputAction } from '../../../../redux/actions/userAction';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { crtUserId, dateFormat } from '../../../../constants/helpers';
import moment from 'moment';

import { BiSortDown, BiSortUp } from "react-icons/bi";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import { PDF_Icon, PPT_ICON, DOC_ICON } from '../../sc_images';

const FinalOutputComp = (props) => {
  const {
    listData,
    toggleDrawer,
    DataRoomDocDetail,
    setOpenForm,
    setDataRoomDocDetail,
    deleteFunction,
    setInputType,
    companyId,
    title
  } = props;

  const shotButton = useRef();

  const [arrowIcon, setArrowIcon] = useState(<RiArrowDownSLine fontSize={25} className="sc_arrow_color" />);
    const [ToggleData, setToggleData] = useState(true);
    const [DivHeight0, setDivHeight0] = useState(300);
      const [name, setName] = useState("");
    // const [ActiveBtn, setActiveBtn] = useState(0);
    const listContainer = useRef();
    const searchContainer = useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [SortType, setSortType] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };




  useEffect(() => {
    setFinalOutputData(listData);
  }, [props])


  const buttonsArr = [
    {
      id:1,
      label:"All",
      value:"All",
    },
    {
      id:2,
      label:"Initial Coverage",
      value:"Initial Coverage",
    },
    {
      id:3,
      label:"Qtr Update",
      value:"Quarterly Update",
      // label:"Other Update",
    },
    // {
    //   id:4,
    //   label:"Forensic",
    // },
    // {
    //   id:5,
    //   label:"Brief Notes",
    // },
    {
      id:6,
      label:"Others",
      value:"Others",
    },
  ];


  const [finalOutputData, setFinalOutputData] = useState(listData);

  const filterfinaloutput = (crtTab) => {
    // a ='all'
    // a.label
    console.log('crtTab.value >??>>> ', crtTab.value)
    let fData=[];
     if(crtTab.value !== 'All'){
       fData = listData.filter((items) => { if (items.DocumentType === crtTab?.value) return items });
      }else{
       fData = listData.filter(items=>{ if (items.DocumentType !== "") return items });
     }


    setFinalOutputData(fData);
 }

  const [ActiveBtn, setActiveBtn] = useState(1)

  const chooseType = (item) =>{
    // alert('sasa');
    filterfinaloutput(item)
    setActiveBtn(item.id)
}




const sortData = (itemData, type) => {
  let sData;



  if (SortType === 'name') {
      if (ActiveBtn) {
          if (ToggleData) {
              sData = finalOutputData.sort((a, b) => a.fileName.localeCompare(b.fileName));
          } else {
              sData = finalOutputData.sort((a, b) => b.fileName.localeCompare(a.fileName));
          }
      } 
      // else {
      //     if (ToggleData) {
      //         sData = itemData.sort((a, b) => a.fileName.localeCompare(b.fileName));
      //     } else {
      //         sData = itemData.sort((a, b) => b.fileName.localeCompare(a.fileName));
      //     }
      // }
  } else {
      if (ActiveBtn) {
          if (ToggleData) {
              sData = finalOutputData.sort((a, b) => {
                  var a1 = moment(a.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                  var b1 = moment(b.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                  var dd = new Date(a1) - new Date(b1);
                  return dd;
              });
          } else {
              sData = finalOutputData.sort((a, b) => {
                  var a1 = moment(a.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
                  var b1 = moment(b.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
                  var dd = new Date(b1) - new Date(a1);
                  return dd;
              });
          }

      } 
      // else {
      //     if (ToggleData) {
      //         sData = itemData.sort((a, b) => {
      //             var a1 = moment(a.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
      //             var b1 = moment(b.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
      //             var dd = new Date(a1) - new Date(b1);
      //             return dd;
      //         });
      //     } else {
      //         sData = itemData.sort((a, b) => {
      //             var a1 = moment(a.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //a.Date
      //             var b1 = moment(b.Date, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss'); //b.Date
      //             var dd = new Date(b1) - new Date(a1);
      //             return dd;
      //         });
      //     }
      // }
  }

  setToggleData(!ToggleData);
}

const onUpdateHeight = () => {
  if (DivHeight0 === 300) {
      setArrowIcon(<RiArrowUpSLine fontSize={25} className="sc_arrow_color" />)
      setDivHeight0('auto')
  } else {
      setArrowIcon(<RiArrowDownSLine fontSize={25} className="sc_arrow_color" />)
      setDivHeight0(300)
  }

}

const handleChange = (event, itemData) => {

  console.log("ActiveBtn 0 0 0 0  00 >>",ActiveBtn)
  // console.log(itemData);
  let arrNew = [];
  itemData.forEach(function (a) {
      console.log("a=",a);
      var fName = a.fileName.toLowerCase();
      var nVal = event.target.value.toLowerCase();
      console.log(fName,"<=>",nVal);
      if (fName.indexOf(nVal) > -1) {
        
          if(buttonsArr.find(item=>item.value===a.DocumentType && item.id===ActiveBtn))
          {
          // if (buttonsArr[ActiveBtn] === a.DocumentType) {
              arrNew.push(a)
          }


           if (ActiveBtn === 1)
           {
              arrNew.push(a)
          }
      }
  });
  setFinalOutputData(arrNew);
  setName(event.target.value);
};

  return (
    <>
      <Box className='cst-box'>
          <Box className='c-head-sec0'>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              marginBottom:'.5rem'
            }}>
                <Typography sx={{
                  fontWeight:'600'
                }}>Final Output</Typography>
                {/* <div>
                    <FaSortAlphaUp className='text-primary' />
                </div> */}

                <>
                            <IconButton ref={shotButton} size="small"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}

                            // onClick={() => sortData(itemData, (title || 'Final Output'))}
                            >
                                {
                                    ToggleData
                                        ?
                                        <FaSortAlphaDown className='text-primary' />
                                        // <BiSortDown />
                                        :
                                        <FaSortAlphaUp className='text-primary' />
                                        // <BiSortUp />
                                }
                            </IconButton>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => [sortData(finalOutputData, (title || 'Final Output')), handleClose(), setSortType('date')]}>Sort by Date</MenuItem>
                                <MenuItem onClick={() => [sortData(finalOutputData, (title || 'Final Output')), handleClose(), setSortType('name')]}>Sort by Name</MenuItem>
                            </Menu>
                        </>

            </Box>
            <Box>
                <ul className='final-out-btn'>
                  {
                    buttonsArr.map((item, i)=>(
                      <li key={i}>
                        <button style={{  }} onClick={(e)=>{
                          chooseType(item);
                      }} className={`btn ${ActiveBtn === item.id ? "primary-btn" : "primary-btn-outline"}`}>{item?.label}</button>
                      </li>
                    ))
                  }
                </ul>

                <Box
                            sx={{
                                marginTop: 2,
                            }}
                        >
                            <TextField
                                onChange={(e) => handleChange(e, (listData && listData))}
                                sx={{
                                    width: '99%',
                                    padding: 0
                                }}
                                size="small"
                                label="Search Document"
                                id="fullWidth"
                                value={name}
                                InputProps={{
                                    endAdornment: <SearchIcon sx={{ color: 'gray' }} />,
                                }}
                                InputLabelProps={{ style: { fontSize: 12, padding: 0, margin: 0 } }}
                                inputProps={{
                                    style: { padding: '0.4rem', fontSize: 15 }
                                }}
                            />
                        </Box>
            </Box>

          </Box>
          <Box className='cst-box-body' sx={{
            minHeight: 300,
            height: DivHeight0}}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
              {
                finalOutputData && finalOutputData.map((value, i) => {

                  let fileLink = '';
                                    let fileExtension = '';
                                    fileLink = value.link;
                                    fileExtension = fileLink.split('.').pop();
                                    if (fileExtension.toLowerCase() === 'pdf') {
                                      let a0 = window.location.href
                                          a0 = a0.split('/')
                                          a0 = a0.slice(0, a0.length-1)
                                          a0 = a0.join('/')
                                      // console.log(a0)
                                        // fileLink = `${a0}/MyPdfViewer`;
                                        if(window.location.href.includes('company-detail')){
                                          fileLink = `/company-detail/MyPdfViewer`;
                                        }else{
                                          fileLink = `/MyPdfViewer`;
                                        }
                                    }

                                    value = { ...value };

                                    value.title = DataRoomDocDetail?.comName;
                                    let fileIcon = "";
                                    if(fileExtension.toLowerCase() === "docx" || fileExtension.toLowerCase() === "doc"){
                                      fileIcon = DOC_ICON;
                                    }
                                    if(fileExtension.toLowerCase() === "pptx" || fileExtension.toLowerCase() === "ppt"){
                                      fileIcon = PPT_ICON;
                                    }
                                    
                  return  (
                    <React.Fragment key={i}>
                      <ListItem
                      sx={{padding:'.5rem 0'}}
                        disableGutters
                        secondaryAction={

                          <>
                          
                          {/* <IconButton onClick={(e)=>{
                              // setInputType(value)
                              setOpenForm('DocumentUpload')
                              setInputType(value)
                              toggleDrawer('bottom', true)(e)
                            }}>
                              <FaEdit />
                            </IconButton> */}
                            {
                              window.location.href.includes('company-detail') && (
                                <>
{
                              crtUserId() == 1 || crtUserId() == value?.UserID 
                              // true
                              ? 
                              <>
                              <IconButton style={{ right: ".5rem" }} aria-label="comment"  onClick={(e)=>{
                                    toggleDrawer('bottom', true)(e);
                                    setOpenForm('UsersSendNotification');
                                    setDataRoomDocDetail({
                                        ...DataRoomDocDetail,
                                        docId:value?.FileID,
                                        dateTime:value?.Date,
                                        docName:value?.fileName,
                                    });
                                }}
                                >
                                  
                              <FaRegEnvelope size="1rem" className='text-primary' />
                            </IconButton>
                            <IconButton style={{ right: "1rem" }} edge="end" aria-label="delete" onClick={(e)=>deleteFunction(value)} >
                                <DeleteIcon />
                            </IconButton>  

                              </>

                              :
                              <>
                              </>
                            }
                                </>

                              )
                            }
                            
                              

                            {/* <IconButton aria-label="comment">
                              <FaRegEnvelope size="1rem" className='text-primary' />
                            </IconButton> */}
                          </>
                        }
                      >


{
                                                    fileExtension.toLowerCase() === 'pdf' ?
                                                        <ListItemButton
                                                            sx={{
                                                                // padding: '0 100px 0 0',
                                                                padding:'0',
                                                                // paddingRight: 100,
                                                                margin: '0',
                                                            }}
                                                            component={Link} target="_blank" to={fileLink} onClick={(e) => {
                                                                console.table(value)
                                                                localStorage.setItem('PDFViewed', window.btoa(value.link));
                                                                localStorage.setItem('itemData1', JSON.stringify(value));
                                                                localStorage.setItem('file_type', title || 'Final Output');
                                                            }}>
                                                            <ListItemIcon sx={{
                                                                padding: '0px',
                                                                minWidth: 'auto',
                                                                paddingRight:'0.3rem'
                                                            }}
                                                            >
                                                                <img src={PDF_Icon} alt="PDF_Icon" className='svc_pdf_img' />
                                                                {/* <AiOutlineFilePdf size={30} /> */}
                                                            </ListItemIcon>
                                                            <ListItemText className='data-room-doc' primary={
                                                                <Typography variant="subtitle2" className='docItem text-color1 dft-text' sx={{
                                                                    // fontWeight:'400',
                                                                    // fontSize: { xs: 10,
                                                                    //   sm: 10,
                                                                    //   md: 11,
                                                                    //   lg: 13,
                                                                    //   xl: 14, },
                                                                }}>  {value.fileName}</Typography>
                                                            } secondary={
                                                                <>
                                                                    <Box display={'flex'}>

       <div style={{display:'flex', fontSize:'10px', gap:'5px'}}>
                            {/* <div><strong>  {value?.UserName}</strong></div> */}
                            <div>{dateFormat(value?.Date, 'DD/MMM/YYYY')}</div>
                            <div>{value?.DocumentType}</div>
                          </div>
                                                                        {/* <Typography fontSize={9} style={{}}>{dateFormat(value.Date, 'DD/MMM/YYYY')}</Typography>
                                                                        {
                                                                            title ?
                                                                                <>
                                                                                    {
                                                                                        ActiveBtn ?
                                                                                            <>
                                                                                                <Typography fontSize={9} fontWeight="bold" sx={{ marginLeft: 0.5 }}>{value.DocumentType}</Typography>
                                                                                            </>
                                                                                            :
                                                                                            'P1'
                                                                                    }
                                                                                </>
                                                                                :
                                                                                'P2'
                                                                        } */}
                                                                    </Box>
                                                                </>
                                                            } />

                                                        </ListItemButton>
                                                        :
                                                        <ListItemButton
                                                            sx={{
                                                                padding: '0',
                                                                margin: '0',
                                                            }}
                                                            rel="noopener noreferrer"
                                                            component={'a'} target="_blank" href={fileLink} onClick={(e) => {
                                                                localStorage.setItem('PDFViewed', window.btoa(value.link));
                                                                localStorage.setItem('file_type', title || 'Final Output');
                                                            }}>
                                                            <ListItemIcon sx={{
                                                                padding: '0px',
                                                                minWidth: 'auto',
                                                                paddingRight:'0.3rem'
                                                            }}
                                                            >
                                                              <img src={fileIcon} alt="PDF_Icon" className='svc_pdf_img' />
                                                                {/* <AiOutlineFilePdf size={30} /> */}
                                                            </ListItemIcon>
                                                            <ListItemText primary={
                                                                <Typography variant="subtitle2" className='docItem dft-text' sx={{ 
                                                                  // color: '#f79044', fontSize: { xs: 10,
                                                                  // sm: 10,
                                                                  // md: 11,
                                                                  // lg: 13,
                                                                  // xl: 14, } 
                                                                }}>{value.fileName}</Typography>
                                                            } secondary={
                                                                <>
                                                                    <Box display={'flex'}>
                                                                    <div style={{
                                                                        display:'flex',
                                                                        fontSize:'10px',
                                                                        gap:'5px'
                                                                      }}>
                                                                      {/* <div><strong>{value?.UserName}</strong></div> */}
                                                                      <div>{dateFormat(value?.Date, 'DD/MMM/YYYY')}</div>
                                                                      <div>{value?.DocumentType}</div>
                                                                    </div>
                                                                        {/* <Typography fontSize={9} style={{}}>{dateFormat(value.Date, 'DD/MMM/YYYY')}</Typography>
                                                                        {
                                                                            title ?
                                                                                <>
                                                                                    {
                                                                                        !ActiveBtn ?
                                                                                            <>
                                                                                                <Typography fontSize={9} fontWeight="bold" sx={{ marginLeft: 0.5 }}>{value.DocumentType}</Typography>
                                                                                            </>
                                                                                            :
                                                                                            null
                                                                                    }
                                                                                </>
                                                                                :
                                                                                null
                                                                        } */}
                                                                    </Box>
                                                                </>
                                                            } />
                                                        </ListItemButton>
                                                }

                        {/* <Box style={{border:"1px solid green"}}>
                          <Typography sx={{ fontSize:'12px' }}>{value?.fileName}</Typography>
                          <div style={{
                            display:'flex',
                            fontSize:'10px',
                            gap:'5px'
                            }}>
                            <div><strong>{value?.UserName}</strong></div>
                            <div>{value?.Date}</div>
                            <div>{value?.DocumentType}</div>
                          </div>
                        </Box> */}
                      </ListItem>
                      {/* <Divider /> */}
                    </React.Fragment>
                  )
                }
                )
              }
            </List>
          </Box>

          <Box className="footer-section"
          sx={{

          }}
      >
          <Divider />
          <div
              style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
              }}
          >
              <IconButton size="small" onClick={() => onUpdateHeight()}>
                  {arrowIcon}
              </IconButton>
          </div>
      </Box>
      </Box>
    </>
  )
}

export default FinalOutputComp